import { MetaFunction } from '@remix-run/cloudflare'

// import AdminDashboard from "~/components/AdminDashboard"
// import SalespersonDashboard from "~/components/SalespersonDashboard"
// import UserDashboard from "~/components/UserDashboard"
import { useAuthStore } from '~/components/AuthStore'
import Breadcrumbs from '~/components/Breadcrumbs'

export const meta: MetaFunction = () => {
  return [{ title: 'Dashboard - Gerente Comercial' }]
}

export default function DashboardPage() {
  const authStore = useAuthStore()
  const current_tenant = authStore.current_tenant

  return (
    <>
      <Breadcrumbs
        parts={[{ name: 'Dashboard', href: '/app', currrent: true }]}
      />
      <div className="my-4" />
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {/* @ts-ignore */}
            <h4>Tenant: {current_tenant?.name}</h4>
          </div>
        </div>
      </div>
    </>
  )
}
